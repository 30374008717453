@import "../../scss/var.scss";

.mask {
  display: block;
  content: "";
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 98;
}
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}
.main {
  padding: $header-height 0 0 $sidebar-width;
  @include breakpoint($tablet-bp) {
    padding-left: 0;
  }
}

content {
  max-width: 95rem;
  margin: 0 auto;
  padding-left: 5rem;
  padding-right: 5rem;
}
