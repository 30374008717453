@import "./var.scss";
@import "./normalize.scss";
html {
  font-size: 100%;
  @include breakpoint($hd-bp) {
    font-size: 62.5%;
  }
}
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background-color: $background;

  font-size: 2rem;
  line-height: 1.5;
  color: $primery;
  overflow: auto;
}
.particles {
  fill: none;
  stroke: #dd913e;
  stroke-width: 5;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-dasharray: 12, 200;
}

.container {
  padding: 0 40px 0 50px;
  max-width: 1320px;
  margin: 0 auto;
  @include breakpoint($mobile-bp) {
    padding: 0 20px;
  }
}

section {
  margin: 40px 0;
}

h2 {
  margin-bottom: 1rem;
}

h3 {
  margin-bottom: 1rem;
}

.grid_col2 {
  display: grid;
  gap: 20px;

  grid-template-columns: 1fr 1fr;
  justify-items: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @include breakpoint($mobile-bp) {
    grid-template-columns: 1fr;
  }
}
.grid_col3 {
  display: grid;
  gap: 20px;

  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
