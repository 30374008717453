@import "../../scss/var.scss";
.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0 80px 0;
  .skeleton {
    width: 33%;
    position: relative;
    margin-top: 80px;
    @include breakpoint($tablet-bp) {
      width: 50%;
    }
    @include breakpoint($mobile-bp) {
      width: 100%;
    }
  }
  .item {
    cursor: zoom-in;
    width: 30.5%;
    position: relative;
    margin-top: 80px;
    aspect-ratio: 3/4;
    background-color: $primery;

    &:hover {
      .square {
        background-position: 100% 0;
      }
    }
    .img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 10;
      box-shadow: 5px -5px 10px $primery;
    }

    .square {
      transition: $transition;

      color: $accent;
      position: absolute;
      padding: 5px 15px;
      width: 90%;
      top: -65px;
      right: -6%;
      z-index: -1;
      max-width: 95%;
      height: 90%;
      background: $square;
      box-shadow: 5px -5px 10px #9ca2b4;
      background-size: 200% 200%;

      div {
        display: flex;
        width: 100%;
        height: 65px;
        p {
          text-align: center;
          margin: auto;
        }
      }
    }
    @include breakpoint($tablet-bp) {
      width: 47%;
      .square {
        top: -55px;
        font-size: 1.6rem;
        div {
          height: 55px;
        }
      }
    }
    @include breakpoint($mobile-bp) {
      width: 95%;
    }
  }
}

.sort {
  display: flex;
  justify-content: center;
  &.fixed {
    align-items: center;
    background-color: $background;
    position: fixed;
    left: $sidebar-width;
    right: 0;
    padding: 10px 0;
    top: $header-height;
    z-index: 90;
    @include breakpoint($tablet-bp) {
      left: 0;
    }
  }
  .filters {
    display: flex;
    justify-content: center;
    @include breakpoint($tablet-bp) {
      flex-wrap: wrap;
      margin-right: 20px;
    }
  }
}
.footer {
  text-align: center;
  margin-top: -40px;
}

.modal {
  display: flex;
  width: 100%;
  height: 100%;
  .slider {
    width: 60%;
    position: relative;
    background-color: $white;
    height: 100%;
  }
  .title {
    color: $accent;
    text-align: center;
    font-size: 2.4rem;
  }
  .description {
    height: 100%;
    overflow: auto;
    padding: 40px 20px;
    width: 40%;
    color: $white;
  }
  @include breakpoint($tablet-bp) {
    flex-direction: column;
    .slider {
      width: 100%;
    }
    .description {
      overflow: visible;
      width: 100%;
    }
  }
}
