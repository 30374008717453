@import "../../scss/var.scss";
.root {
  width: 100%;
  background-color: $lighten;
  box-shadow: 0 -7px 10px $primery;
  padding: 20px;
  align-items: center;
  text-align: center;
  color: $white;

  .copyright {
    font-size: 1.4rem;
  }
}
