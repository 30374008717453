@import "../../scss/var.scss";
.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: darken($background, 40);
  transition: all 2s;
  opacity: 1;
  .slide {
    height: 100%;
    position: relative;
    &.fadeInAnimation {
      animation: fadeIn 1.5s;
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;

      display: block;
      &.loading {
        filter: blur(5px);
        clip-path: inset(0);
      }
    }
  }
  .arrowsWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;

    .arrow {
      display: block;
      font-size: 0;
      color: transparent;
      outline: none;
      border: 0;
      background: none;
      pointer-events: none;

      transform: translateY(-50%);
      &:focus {
        outline: 0;
      }
      &:after {
        content: "";
        display: block;
        width: 16px;
        height: 16px;

        border-top: 2px solid $primery;
        border-left: 2px solid $primery;
        box-shadow: -4px -4px 4px 0px #fff;
      }
      &.prev {
        margin: 13px 0 0 13px;
        transform: rotate(-45deg);
      }
      &.next {
        margin: 13px 13px 0 0;

        transform: rotate(135deg);
      }
    }
  }
  .dotsWrapper {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    .dot {
      font-size: 0;
      color: transparent;

      display: block;
      width: 16px;
      height: 16px;
      margin: 6px;
      border: 2px solid $primery;
      border-radius: 50%;
      background: none;
      cursor: pointer;
      box-shadow: 0 0 6px 3px #fff;
      &.active {
        background: $primery;
      }
    }
  }
}

.svg {
  display: block;
  width: 50px;
  animation: k-rotate 4s ease-in-out infinite;
  position: absolute;

  top: calc(50% - 25px);
  left: calc(50% - 25px);
}
@keyframes k-rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
