@import "../../scss/var.scss";
.root {
  width: 100%;
  height: 100%;
  position: absolute;
  &::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    box-shadow: inset 0 0 150px 20px $primery;
  }
}
.item {
  position: absolute;
  height: 40vh;
  bottom: 50px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  transition: all 2s linear;
}
.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  & img {
    display: block;
    width: 100%;
    height: auto;
  }
}
.left_img {
  position: absolute;
  bottom: 0;
  left: 0;
  clip-path: polygon(0 0, 50% 0, 50% 100%, 0 100%);
  animation: k-left 2s ease-in-out infinite;
  animation-direction: alternate;
}
.right_img {
  position: absolute;
  bottom: 0;
  left: 0;
  clip-path: polygon(50% 0, 100% 0, 100% 100%, 50% 100%);
  animation: k-right 2s ease-in-out infinite;
  animation-direction: alternate;
  margin-left: -1px;
}

.right {
  transform: translate3d(70%, -20px, -20px) scale(0.8);
  opacity: 0.8;
}
.left {
  opacity: 0.8;

  transform: translate3d(-170%, -20px, -20px) scale(0.8);
}

.next {
  transform: translate3d(70%, -20px, -20px) scale(0.8);
  opacity: 0;
}

.prev {
  transform: translate3d(-170%, -20px, -20px) scale(0.8);

  opacity: 0;
}

@keyframes k-left {
  0% {
    transform: rotateY(-1deg) scaleX(0.92);
  }
  100% {
    transform: rotateY(0deg) scaleX(1);
  }
}
@keyframes k-right {
  0% {
    transform: rotateY(0deg) scaleX(1);
  }
  100% {
    transform: rotateY(1deg) scaleX(0.92);
  }
}
