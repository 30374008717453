@import "../../scss/var.scss";

.intro {
  width: 100%;
  height: calc(100vh - #{$header-height});
  position: relative;
}

.pfone {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
