@import "../../scss/var.scss";

.mask {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1010;
}
.wrapper {
  position: relative;
  transform: translateY(0);
  opacity: 1;
  max-width: 991px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $primery;
  @media screen and (max-width: 991px) {
    max-width: 95vw;

    -webkit-scrollbar/* Hide scrollbar for Chrome, Safari and Opera */ {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.close {
  position: absolute;
  cursor: pointer;
  right: 15px;
  top: 15px;
  &.form {
    right: 30px;
    top: 30px;
  }
  width: 32px;
  height: 32px;
  background: transparent;
  border: none;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    top: -2px;
    left: 15px;
    content: " ";
    height: 33px;
    width: 3px;
    background-color: $white;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}
