@import "../../scss/var.scss";
.title {
  width: 100%;
  margin: 0 auto 2rem;
}
.preview {
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 3rem;
  overflow: hidden;
  background-color: $secondary;
  color: $white;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  width: 48%;
  @include breakpoint($tablet-bp) {
    width: 100%;
  }
  -ms-flex-direction: column;
  flex-direction: column;
  .banner {
    display: block;
    width: 100%;
    height: 300px;
    & img {
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
    }
  }
  .content {
    padding: 1rem;
    text-align: center;
    .title {
      font-size: 2.2rem;
      a:hover,
      a:focus {
        color: $accent;
      }
    }
    .description {
      margin-bottom: 2rem;
      font-size: 1.6rem;
      opacity: 0.8;
      max-height: 200px;
      overflow: hidden;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      font-size: 2rem;

      a:hover,
      a:focus {
        color: $accent;
      }
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .item {
    cursor: pointer;
    margin: 0 0.5rem;
    margin-bottom: 1rem;
    padding: 0.4rem 0.9rem;
    background-color: #ea8c1e;
    border-radius: 3px;
    font-size: 1.6rem;
    line-height: 1.3;
    color: $white;
    text-align: center;
  }
}

.post {
  padding: 2.5rem;
  overflow: hidden;
  max-width: 800px;
  background-color: $secondary;
  margin: 3rem auto;
  box-shadow: 0 0 25px $secondary;
  border-radius: 5px;
  color: $white;

  .back {
    display: flex;
    justify-content: flex-end;
    margin: -2rem 0 -1rem;
    div {
      opacity: 0.8;
      cursor: pointer;
      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
    }
  }
  .header {
    padding-bottom: 1rem;
    border-bottom: 2px solid $white;
    .title {
      text-align: left;
      font-size: 2.4rem;
    }
    .info {
      display: flex;
      flex-wrap: wrap;
      .item {
        margin-right: 2rem;

        position: relative;
        &:first-child {
          opacity: 0.7;
          &::before {
            display: none;
          }
        }

        &.link {
          opacity: 0.7;
          cursor: pointer;
          &:hover {
            opacity: 1;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .text {
    margin: 1rem 0;
    border-bottom: 2px solid $white;
    padding-bottom: 1rem;
    line-height: 1.3;
    h1,
    h2,
    h3,
    h4 {
      color: $accent;
    }
    ul,
    ol {
      margin-left: 1rem;
    }

    ol {
      list-style: decimal;
      list-style-position: inside;
    }

    p {
      text-indent: 1.5rem;

      margin-bottom: 1rem;
    }

    a {
      color: $accent;
    }

    table {
      margin-bottom: 2rem;
      width: 100%;
    }

    img {
      display: block;
      margin: 0.5rem auto;
      max-width: 100%;
      height: auto;
    }
    .embed,
    .fr-video {
      display: block;
      position: relative;
      overflow: hidden;
    }

    .embed:before,
    .fr-video:before {
      content: "";
      display: block;
      padding-top: 56.25%;
    }

    .embed iframe,
    .embed video,
    .embed object,
    .embed embed,
    .fr-video iframe,
    .fr-video video,
    .fr-video object,
    .fr-video embed {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
    }
  }
  .related {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: 48%;
      margin-bottom: 2rem;
      .title {
        color: lighten($accent, 20);
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        &:hover {
          color: $accent;
          text-decoration: underline;
        }
      }
      .date {
        opacity: 0.7;
      }
    }
  }
}
