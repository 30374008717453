@import "../../scss/var.scss";

.sort {
  position: relative;
  display: inline-block;

  span {
    color: $accent;
    margin: 0 1rem 0;
    border-bottom: 1px dashed $accent;
    cursor: pointer;
  }
}

.popup {
  position: absolute;
  right: -10px;
  top: 100%;
  z-index: 100;
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  padding: 10px 0;
  max-width: 200px;
  max-height: 70vh;
  overflow-y: scroll;
  overflow-x: hidden;

  ul {
    li {
      padding: 12px 15px;
      cursor: pointer;

      &.active,
      &:hover {
        background: rgba(254, 95, 30, 0.05);
      }

      &.active {
        font-weight: bold;
        color: $accent;
      }
    }
  }
}
