.slider {
  position: absolute;
  width: 100%;
  height: 100%;

  overflow: hidden;
}

.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 2s;
  opacity: 1;
  & img {
    display: block;
    width: 100%;
    height: 100%;
  }
}
.prev,
.next {
  opacity: 0;
}
