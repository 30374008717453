@import "../../../scss/var.scss";
.form {
  *::placeholder {
    color: $white;
    opacity: 0.8;
  }
  width: 400px;
  max-width: 95%;
  margin: 0 auto;
  font-size: 1.5rem;

  .item {
    margin-bottom: 10px;
    border-bottom: 2px solid $white;
    padding-bottom: 10px;

    .input {
      width: 100%;
      border: none;
      background: transparent;
      height: 30px;
      color: $white;
    }
    .textarea {
      width: 100%;
      flex-grow: 1;
      padding: 2px 2px 5px;
      overflow: hidden;
      height: 30px;
      vertical-align: middle;
      border: none;
      background: transparent;
      resize: none;
      color: $white;
    }
    .error {
      border: 2px solid $accent;
    }
    .errorMsg {
      color: $accent;
    }
  }
  .footer {
    margin: 10px 0 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    text-align: left;
    & li {
      width: 48%;
      &:last-child {
        text-align: right;
      }
    }
    .link {
      color: $accent;
    }
  }
}
