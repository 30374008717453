@import "../../scss/var.scss";

.apartament_grid {
  cursor: zoom-in;
  display: grid;
  grid-template-columns: 2fr 3fr 2fr 300px;
  column-gap: 10px;
  width: 100%;
  @include breakpoint($laptop-bp) {
    grid-template-columns: 2fr 3fr 2fr 200px;
  }
  @include breakpoint($mobile-bp) {
    grid-template-columns: 2fr 3fr 2fr;
    column-gap: 5px;
  }
  margin-bottom: 60px;
  & div {
    max-height: 50vh;
  }
  & div img {
    transition: $transition;
  }

  &.animate div * {
    opacity: 0;
    transform: rotateZ(-90deg) scale(0.5);
  }

  &.animate div img:last-child {
    transform: translateY(-60%) rotateX(-90deg);
  }
  &.animate div img:first-child {
    transform: translateX(-60%) rotateY(-90deg);
  }
}
.grid2 {
  grid-template-columns: 5fr 4fr 5fr 300px;
  @include breakpoint($laptop-bp) {
    grid-template-columns: 5fr 4fr 5fr 200px;
  }
  @include breakpoint($mobile-bp) {
    grid-template-columns: 5fr 4fr 5fr;
  }
}

.apartament_img {
  display: block;
  width: 100%;

  height: 50%;
  //aspect-ratio: 3/4;

  object-fit: cover;

  padding-bottom: 10px;
  @include breakpoint($mobile-bp) {
    padding-bottom: 5px;
  }
  &:last-child {
    padding-bottom: 0;
  }
  &.ratio {
    //aspect-ratio: 4/3;
    height: 33.3333%;
  }
  &.ratio2 {
    height: 40%;
    //aspect-ratio: 4/3;
  }
  &.ratio3 {
    height: 50%;
    //aspect-ratio: 4/3;
  }
}
.apartament_info {
  display: block;
  height: 100%;
  overflow: hidden;
  flex-grow: 1;
  background-color: $primery;
  position: relative;
  color: $white;
  font-size: 1.6rem;

  p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 40px;
    overflow: hidden;
    padding: 1rem;
  }
  div {
    position: absolute;
    color: $accent;
    text-align: right;
    right: 10px;
    bottom: 0;
    height: 40px;
    &:hover {
      text-decoration: underline;
    }
  }
  @include breakpoint($tablet-bp) {
    font-size: 1.4rem;
  }
}
.apartament_col2 {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.column {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.end {
  justify-content: flex-end;
}

.apartament_square {
  background-color: $primery;
  color: $white;
  position: absolute;
  line-height: 1.2;
  top: 35%;
  left: -20%;
  width: 140%;
  height: 30%;
  padding: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  text-align: center;
  z-index: -1;
  font-size: 1.4rem;
}
.apartament_square2 {
  top: 0;
  left: -30%;
  width: 130%;
  height: 30%;
  align-content: flex-start;
  font-size: 1.4rem;
  text-align: right;
  padding: 5px 10px 25px 25px;
}

.apartament_square3 {
  top: 0;
  padding-top: 8px;
  align-content: flex-start;
  @include breakpoint($mobile-bp) {
    font-size: 1.6rem;
  }
}

.footer {
  text-align: center;
  margin-top: -40px;
}
