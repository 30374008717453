@import "../../scss/var.scss";
.root {
  padding: 0 1.5rem;

  position: fixed;
  top: 0;
  left: $sidebar-width;
  background-color: $lighten;
  box-shadow: 0 7px 10px $primery;
  display: flex;
  justify-content: space-between;
  width: calc(100% - #{$sidebar-width});
  height: $header-height;
  @include breakpoint($tablet-bp) {
    left: 0;
    width: 100%;
  }
  .right,
  .left {
    align-items: center;
    display: flex;
  }
  .right {
    padding: 1rem 0;
    .logo {
      display: block;
      width: 50px;
      height: 50px;
      margin-right: 1.5rem;
      @include breakpoint($tablet-bp) {
        margin: 0;
      }
    }
    .contact {
      color: $accent;
      margin-left: 2rem;
      @include breakpoint($laptop-bp) {
        display: none;
      }
      @include breakpoint($tablet-bp) {
        display: block;
      }
      @include breakpoint($mobile-bp) {
        display: none;
      }
    }
  }
}

.list {
  display: flex;
  justify-content: flex-start;

  .item {
    position: relative;
    padding: 1rem 0;
    height: 100%;
    border-radius: 7px 7px 0 0;
    &:hover {
      background-color: $primery;
      .subnav {
        display: block;
      }
    }
    .link {
      display: block;
      padding: 0 1rem;
      color: $white;
      text-decoration: none;
      &:active,
      &.active,
      &:focus,
      &:hover {
        color: $accent;
      }
      transition: background-color 0.2s linear;
      &.has_subnav {
        position: relative;
        padding-right: 3.5rem;
      }
      &.has_subnav:after {
        content: "";
        display: block;
        margin-top: 2px;
        border-style: solid;
        border-width: 6px 5px 0 5px;
        border-color: #fff transparent transparent transparent;
        position: absolute;
        top: 50%;
        right: 1.35rem;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
      }
    }
    .subnav {
      display: none;
      width: 16.5rem;
      padding: 0.5rem 0;
      background-color: $primery;
      box-shadow: 0 6px 8px $primery;
      position: absolute;
      top: 100%;
      left: 0;
      border-radius: 0 7px 7px 7px;
      .sublink {
        cursor: pointer;
        display: block;
        padding: 0.45rem 1.2rem;
        font-size: 1.8rem;
        color: #fff;
        text-decoration: none;
        transition: background-color 0.2s ease-in-out;
        &:hover {
          color: $accent;
        }
        &.active {
          color: $accent;
        }
      }
    }
  }
}
.header_text {
  color: $white;
}
.burger {
  width: 2rem;
  cursor: pointer;
  height: 1rem;
  padding: 0;
  background: none;
  border: none;
  font-size: 0;
  color: transparent;
  position: absolute;
  top: 50%;
  left: 1.5rem;
  transform: translateY(-50%);

  &:before,
  &:after,
  & span {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #fff;
    position: absolute;
    left: 0;
  }
  &:before,
  &:after {
    content: "";
    transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  & span {
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.2s linear;
  }
  &.clouse {
    span {
      opacity: 0;
    }
    &:before,
    &:after {
      top: 50%;
    }
    &:before {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:after {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
}
.form {
  color: $white;
  text-align: center;
  margin: 4rem 2rem;
}
