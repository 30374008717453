@import "../../scss/var.scss";

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #222222;
  transition: 0.5s;
  z-index: 2000;
  display: flex;
  .logo {
    width: 300px;
    height: 300px;
    position: relative;
    margin: auto;
  }
}
