$primery: #373748;
$background: #b4abab;
$secondary: #605d6b;
$lighten: darken(#9194a0, 15);
$square: linear-gradient(
  to top right,
  $primery 15%,
  #969bac 40%,
  $secondary 50%,
  $primery 65%,
  #848997 72%,
  $secondary 90%
);
$white: #faf4e1;
$accent: #fdc91e;
$sidebar-width: 400px;
$header-height: 60px;

$transition: all 0.6s linear;

$hd-bp: 2300px;
$laptop-bp: 1280px;
$tablet-bp: 991px;
$mobile-bp: 575px;

@mixin breakpoint($bp) {
  @media screen and (max-width: $bp) {
    @content;
  }
}
@include breakpoint($tablet-bp) {
}
