@font-face {
  src: local("Pacifico"),
    url("../fonts/Pacifico-Regular.ttf") format("truetype");
  font-family: "Pacifico";
}
@font-face {
  src: local("Lobster"), url("../fonts/Lobster-Regular.ttf") format("truetype");
  font-family: "Lobster";
}

button:focus {
  outline: none;
}
ul {
  list-style: none;
}
* {
  padding: 0;
  margin: 0;
  outline: none;
  font-family: "Lobster", cursive;

  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: $primery darken($background, 20);

  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: darken($background, 20);
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primery;
    border-radius: 5px;
    border: 3px solid darken($background, 20);
  }
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4 {
  text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  margin: 0;
  padding: 0;
  font: inherit;
  vertical-align: baseline;
}
@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}
