@import "../../scss/var.scss";
.root {
  position: fixed;
  z-index: 99;
  top: 0;
  background-color: $primery;
  color: $white;
  width: $sidebar-width;
  max-width: 95vw;
  box-shadow: 10px 0px 10px $primery;
  bottom: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: auto;
  transition: transform 0.6s linear;

  &.hidden {
    transform: translateX(-110%);
  }
  &.mobile {
    padding-top: $header-height;
  }
  .nav {
    .item {
      text-transform: capitalize;
      border-bottom: 2px solid $white;

      .link {
        cursor: pointer;
        padding: 20px;
        display: block;

        transition: color 0.2s linear;
        &:hover,
        &:focus,
        &.active {
          color: $accent;
        }
      }
      .subnav {
        padding-left: 40px;
        color: $white;
        background-color: lighten($primery, 5);
        .sublink {
          cursor: pointer;

          padding: 10px;
          transition: color 0.2s linear;
          position: relative;
          &:hover,
          &:focus,
          &.active {
            color: $accent;
          }
          &:before {
            content: "";
            display: block;
            width: 5px;
            height: 5px;

            background-color: $white;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: -5px;
            z-index: 1;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

.img {
  width: $sidebar-width;
  height: 220px;
  object-fit: cover;
  display: block;
}

.profile {
  margin-top: -40px;
  padding: 0 20px;
  justify-content: center;
  text-align: center;
}

.avatar {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 20px rgb(117, 116, 116);
}
.initials {
  margin: -7px 0;
  font-size: 2rem;
}

.title {
  font-size: 1.7rem;
  font-style: italic;
  line-height: 1;
  margin-bottom: 1rem;
}

.text {
  font-size: 1.7rem;
  margin-bottom: 5px;
}

.footer {
  font-size: 1.7rem;
  text-align: center;
  & li {
    text-decoration: none;
    color: $white;
    padding: 5px 20px;
    border-bottom: 1px solid $white;
    & a {
      color: $accent;
    }
  }
  & li:first-child {
    border-top: 1px solid $white;
  }
}
