@import "../../scss/var.scss";

.root {
  display: inline-block;
  vertical-align: top;
  padding: 0.5em 1em;
  margin: 0.3rem;
  line-height: 1;
  font-size: 1.8rem;
  color: $white;
  cursor: pointer;
  border: none;
  background-size: 300% 100%;

  border-radius: 2em;

  transition: all 0.7s ease-in-out;
  &:hover {
    background-position: 90% 0;
  }
  background-image: linear-gradient(
    to right,
    #539191,
    #1c3a3a,
    #268678,
    #1a188d
  );
  box-shadow: 0 4px 15px 0 #1c3a3a;

  &.accent {
    background-image: linear-gradient(
      to right,
      #5f5f5f,
      #cfb0b0,
      #a09c9c,
      #805b5b
    );
    box-shadow: 0 5px 15px #5f5f5f;
  }
}
